import {toast} from "react-toastify";
import {LOGIN, LOGOUT} from "./constants";
import {handleError} from "./common";

const logout = () => {
    fetch(LOGOUT, {
        method: 'POST',
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }
            if (response.ok) {
                console.log("Logged out successfully.");
            } else {
                toast.error("Failed to log out.")
            }
        })
        .catch(handleError);
}

const login = (data: FormData) => {

    fetch(LOGIN, {
        method: 'POST',
        body: data,
        redirect: "follow"
    })
        .then((response) => {
            console.log(response);
            console.log(response.redirected);
            if (response.redirected) {
                console.log(response.url);
                window.location.href = response.url;
            }
            if (response.ok) {
                console.log('Login successful!');
            } else {
                toast.error("Failed to log in.")
            }
        })
        .catch(handleError);
};

export {logout, login}