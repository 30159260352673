import React from 'react';
import Copyright from "../components/Copyright";


const NotAllowed = () => {
    return (
        <>
            <div style={{textAlign: 'center'}}>
                <h1>Not allowed</h1>
                <img
                    src="https://media.giphy.com/media/IdOCJGlyxBfLBqiwOM/giphy.gif"
                    alt="Not Allwed"
                    style={{width: '300px', height: '300px'}}
                />
                <p>Sorry but you are not allowed to access this resource, try the mobile app</p>
            </div>
            <Copyright sx={{mt: 8, mb: 4}}/>
        </>
    );
};

export default NotAllowed;
