import * as React from "react";
import {useEffect} from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "./TablePaginationActions";
import {PageResult} from "../../api/classes";

interface SimpleTableProps<T> {
    defaultPage: number | null;
    defaultRowsPerPage: number | null;
    tableHead: () => React.JSX.Element;
    getPage: (page: number, pageSize: number) => Promise<PageResult<T>>;
    getRow: (entity: T, refresh: () => void) => React.JSX.Element;
}

export default function SimpleTable<T>(props: SimpleTableProps<T>) {

    const {defaultPage, defaultRowsPerPage, tableHead, getPage, getRow} = props;

    const [page, setPage] = React.useState(defaultPage || 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage || 25);
    const [emptyRows, setEmptyRows] = React.useState(defaultRowsPerPage || 25);

    const [body, setBody] = React.useState<PageResult<T> | null>(null);

    const refresh = () => {
        getPage(page, rowsPerPage).then(value => {
            if (value === undefined) {
                return;
            }
            setBody(value);
            setEmptyRows(page - (value.totalElements % rowsPerPage));
        });
    }

    // noinspection JSVoidFunctionReturnValueUsed
    useEffect(() => {
        refresh();
        // eslint-disable-next-line
    }, [getPage, page, rowsPerPage]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 500}} aria-label="custom pagination table">
                {tableHead()}
                <TableBody>
                    {
                        body?.content?.map((row) => getRow(row, refresh)) || <></>
                    }
                    {emptyRows > 0 && (
                        <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={6}/>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            colSpan={3}
                            count={body?.totalElements || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    "aria-label": "rows per page"
                                },
                                native: true
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}