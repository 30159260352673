import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

function toISO(date: dayjs.Dayjs | null) {
    if (!date) {
        return null;
    }
    return date.format(dateFormat);
}

function toDateTimePicker(date: string | null) {
    if (!date) {
        return null;
    }
    return dayjs(date);
}

export {toISO, toDateTimePicker}