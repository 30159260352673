import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useNavigate} from 'react-router-dom';
import {createUser} from "../../../api/users";
import {User} from "../../../api/classes";
import {toast} from "react-toastify";
import {MuiChipsInput} from 'mui-chips-input'


export default function CreateUser() {
    const navigate = useNavigate();

    const [userId, setUserId] = React.useState<string | null>('');
    const [username, setUsername] = React.useState<string | null>('');
    const [password, setPassword] = React.useState<string | null>('');
    const [enabled, setEnabled] = React.useState(true);
    const [authorities, setAuthorities] = React.useState<string[]>([]);
    const [body] = React.useState<User>(new User());

    const saveUser = () => {
        body.id = userId;
        body.username = username;
        body.password = password;
        body.enabled = enabled;
        body.authorities = authorities;

        createUser(body)
            .then(value => {
                if (value === undefined) {
                    return;
                }
                console.log(value);
                toast.success("User created.");
                fillInUser(`../${value?.id}`);
            })

    }

    const fillInUser = (id: string) => {
        navigate(id, {replace: true});
    }

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: "white",
                flexGrow: 1,
                overflow: 'auto',
            }}
        >
            <Container maxWidth="sm" sx={{mt: 4, mb: 4}}>
                <Typography component="h1" variant="h5" align={"center"}>
                    Create user
                </Typography>
                <Box component="form" sx={{mt: 1}}>
                    <TextField
                        id="userId"
                        label="User ID"
                        margin="normal"
                        value={userId}
                        onChange={event => setUserId(event.target.value)}
                        required
                        fullWidth
                    />
                    <TextField
                        id="username"
                        label="Username"
                        margin="normal"
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                        required
                        fullWidth
                    />
                    <TextField
                        id="password"
                        label="Password"
                        margin="normal"
                        value={password}
                        type={"password"}
                        onChange={event => setPassword(event.target.value)}
                        required
                        fullWidth
                    />

                    <Box width="100%">

                        <FormControlLabel
                            control={<Checkbox
                                id="enabled"
                                checked={enabled}
                                onChange={event => setEnabled(event.target.checked)}
                                color="primary"/>}
                            label="Enabled"
                        />
                    </Box>


                    <MuiChipsInput value={authorities} onChange={value => setAuthorities(value)}/>

                    <Button
                        fullWidth
                        variant="contained"
                        color={"secondary"}
                        onClick={() => saveUser()}
                        sx={{mt: 3, mb: 2}}
                    >
                        Save
                    </Button>

                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => navigate(-1)}
                        sx={{mt: 3, mb: 2}}
                    >
                        Cancel
                    </Button>
                </Box>
            </Container>
        </Box>

    );
}