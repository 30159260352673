import {toast} from "react-toastify";
import {RESET_PASSWORD} from "./constants";
import {handleError} from "./common";
import {EmailBody, PasswordBody} from "./classes";

const requestResetPassword = (email: string) => {
    let emailBody = new EmailBody();
    emailBody.email = email;

    return fetch(RESET_PASSWORD, {
        method: 'POST',
        body: JSON.stringify(emailBody),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return true;
            } else {
                toast.error("Failed to request password reset")
                return false;
            }
        })
        .catch(handleError);
}
const resetPassword = (id: string, password: string) => {
    let passwordBody = new PasswordBody();
    passwordBody.password = password;

    return fetch(RESET_PASSWORD + "/" + id, {
        method: 'POST',
        body: JSON.stringify(passwordBody),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                toast.success("Password reset was successful, please try to log in again")
                return true;
            } else {
                toast.error("Failed to reset password")
                return false;
            }
        })
        .catch(handleError);
}

const checkResetPassword = (id: string) => {
    return fetch(RESET_PASSWORD + "/" + id, {
        method: 'GET'
    })
        .then((response) => {
            if (response.ok) {
                return true;
            } else {
                toast.error("Invalid password reset request, please try again")
                return false;
            }
        })
        .catch(handleError);
}

export {resetPassword, requestResetPassword, checkResetPassword}