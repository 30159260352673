import SimpleTable from "../../components/table/SimpleTable";
import {deleteClient, getClients} from "../../api/clients";
import {Client, PageResult} from "../../api/classes";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import * as React from "react";
import BasicMenu from "../../components/table/BasicMenu";
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";

export default function DashboardClients() {

    const navigate = useNavigate();

    const getClientPage = async (page: number, pageSize: number): Promise<PageResult<Client>> => {
        return await getClients(page, pageSize);
    }

    const getTableHead = (): React.JSX.Element => {
        return (
            <TableHead>
                <TableRow key={"clients-table-head"}>
                    <TableCell>Client name</TableCell>
                    <TableCell>Redirect URI</TableCell>
                    <TableCell>
                        <IconButton
                            id="add-client-button"
                            onClick={() => navigate("new")}
                        >
                            <AddIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableHead>
        )
    }

    const getRow = (client: Client, refresh: () => void): React.JSX.Element => {
        let id = client.id || "unknown";
        return (
            <TableRow key={client.id}>
                <TableCell>{client.clientName}</TableCell>
                <TableCell>{Array.from(client.redirectUris).join('\n')}</TableCell>
                <TableCell><BasicMenu id={id} isDelete={true} disable={() => {
                    deleteClient(id).then(() => {
                        refresh();
                    });
                }}/></TableCell>

            </TableRow>
        )
    }


    return (
        <>
            <SimpleTable defaultPage={0} defaultRowsPerPage={25} tableHead={getTableHead} getPage={getClientPage}
                         getRow={getRow}/>
        </>
    )
}