import * as React from 'react';
import {useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useNavigate, useParams} from 'react-router-dom';
import Copyright from "../components/Copyright";
import {checkResetPassword, resetPassword} from "../api/password";

import PasswordIcon from '@mui/icons-material/Password';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const defaultTheme = createTheme();
const PASSWORD_REGEX = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$");

export default function ResetPasswordConfirm() {
    const {id} = useParams();
    const [password, setPassword] = React.useState<string>('');
    const [confirmPassword, setConfirmPassword] = React.useState<string>('');
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [passwordAccepted, setPasswordAccepted] = React.useState<boolean>(true);
    const [passwordsMatch, setPasswordsMatch] = React.useState<boolean>(true);
    const [allowSubmit, setAllowSubmit] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        if (id != null) {
            resetPassword(id, password).then(ok => {
                if (ok) {
                    setSuccess(true);
                }
            });
        }
    }

    useEffect(() => {
        if (id != null) {
            checkResetPassword(id).then(ok => {
                if (!ok) {
                    navigate("/reset-password");
                }
            })
        } else {
            navigate("/login")
        }
    }, [id, navigate]);

    // passwordsMatch && passwordAccepted && password.length > 0 && confirmPassword.length > 0;

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                {
                    !success ?
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                                <PasswordIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Reset Password
                            </Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="New password"
                                error={!passwordAccepted}
                                helperText={!passwordAccepted && "Minimum 8 characters, 1 uppercase, 1 lowercase and 1 number"}
                                type={showPassword ? "text" : "password"}
                                autoFocus
                                onChange={event => {
                                    setPassword(event.target.value);
                                    if (PASSWORD_REGEX.test(event.target.value)) {
                                        setPasswordAccepted(true);
                                        let passwordsMatch = event.target.value === confirmPassword;
                                        setPasswordsMatch(passwordsMatch);
                                        setAllowSubmit(passwordsMatch);
                                    } else {
                                        setPasswordAccepted(false);
                                        setAllowSubmit(false);
                                        setPasswordsMatch(event.target.value === confirmPassword);
                                    }
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="confirm-password"
                                label="Confirm password"
                                type={showPassword ? "text" : "password"}
                                error={!passwordsMatch && confirmPassword.length > 0}
                                helperText={!passwordsMatch && confirmPassword.length > 0 && "Passwords do not match"}
                                onChange={event => {
                                    setConfirmPassword(event.target.value);
                                    if (event.target.value === password) {
                                        setPasswordsMatch(true)
                                        setAllowSubmit(passwordAccepted)
                                    } else {
                                        setPasswordsMatch(false)
                                        setAllowSubmit(false);
                                    }
                                }}
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value="remember-me"
                                    id="show-password"
                                    checked={showPassword}
                                    onChange={_ => setShowPassword(!showPassword)}
                                    color="primary"/>}
                                label="Show password"
                            />
                            <Button
                                fullWidth
                                onClick={_ => handleSubmit()}
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                disabled={!allowSubmit}
                            >
                                Reset password
                            </Button>
                        </Box>
                        :
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                                <CheckCircleIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Password reset was successful, you can log in with your new password
                            </Typography>
                        </Box>
                }

                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}