import * as React from 'react';
import {useEffect} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useNavigate, useParams} from 'react-router-dom';
import {createClient, getClient, updateClient} from "../../../api/clients";
import {
    AuthorizationGrantType,
    Client,
    ClientAuthenticationMethod,
    ClientSettings,
    TokenSettings
} from "../../../api/classes";
import {toast} from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import {Chip, MenuItem, OutlinedInput, Select} from "@mui/material";
import {MuiChipsInput} from 'mui-chips-input';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {toDateTimePicker, toISO} from '../../../utils/dateutils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface CreateOrEditClientProps {
    isCreate?: boolean
}

export default function CreateOrEditClient(props: CreateOrEditClientProps) {
    const {id = "unknown"} = useParams();
    const {isCreate = false} = props;
    const navigate = useNavigate();

    const [body, setBody] = React.useState<Client>(new Client());

    const saveClient = () => {
        if (!body) {
            toast.error("Cannot save, body is null.");
            return
        }

        if (isCreate) {
            createClient(body)
                .then(value => {
                    if (value === undefined) {
                        return;
                    }
                    console.log(value);
                    toast.success("Client created.");
                    navigate((`../${value?.id}`), {replace: true});
                })
        } else {
            updateClient(id, body)
                .then(value => {
                    if (value === undefined) {
                        return;
                    }
                    toast.success("Client updated.");
                    fillInClient();
                })
        }

    }

    const updateBody = (update: Partial<Client>) => {
        if (body) {
            setBody({...body, ...update})
        }
    }

    const updateClientSettings = (update: Partial<ClientSettings>) => {
        if (body) {
            setBody({...body, clientSettings: {...body.clientSettings, ...update}})
        }
    }

    const updateTokenSettings = (update: Partial<TokenSettings>) => {
        if (body) {
            setBody({...body, tokenSettings: {...body.tokenSettings, ...update}})
        }
    }

    const fillInClient = () => {
        getClient(id)
            .then(client => {
                if (client === undefined) {
                    return;
                }
                setBody(client);
            });
    }

    useEffect(() => {
        if (!isCreate) {
            fillInClient();
        }
        // eslint-disable-next-line
    }, [isCreate, id]);


    return (
        <Box
            component="main"
            sx={{
                backgroundColor: "white",
                flexGrow: 1,
                overflow: 'auto',
            }}
        >
            <Container maxWidth="sm" sx={{mt: 4, mb: 4}}>
                <Typography component="h1" variant="h5" align={"center"}>
                    {isCreate ? 'Create client' : 'Edit client'}
                </Typography>
                <Box component="form" sx={{mt: 1}}>
                    <TextField
                        id="clientId"
                        label="Client ID"
                        margin="normal"
                        value={body.clientId || ''}
                        onChange={event => updateBody({clientId: event.target.value})}
                        required
                        fullWidth
                    />

                    <TextField
                        id="clientSecret"
                        label="Client Secret"
                        margin="normal"
                        type={"password"}
                        value={body.clientSecret || ''}
                        onChange={event => updateBody({clientSecret: event.target.value})}
                        required
                        fullWidth
                    />

                    <DateTimePicker
                        label="Client issued at"
                        value={toDateTimePicker(body.clientIdIssuedAt)}
                        onChange={value => {
                            updateBody({clientIdIssuedAt: toISO(value)});
                        }}
                        readOnly
                    />

                    <DateTimePicker
                        label="Client secret expires at"
                        value={toDateTimePicker(body.clientSecretExpiresAt)}
                        onChange={value => {
                            updateBody({clientSecretExpiresAt: toISO(value)});
                        }}

                    />


                    <TextField
                        id="clientName"
                        label="Client name"
                        margin="normal"
                        value={body.clientName || ''}
                        onChange={event => updateBody({clientName: event.target.value})}
                        required
                        fullWidth
                    />

                    <InputLabel id="clientAuthenticationMethods-label">Authentication methods</InputLabel>
                    <Select
                        labelId="clientAuthenticationMethods-label"
                        id="clientAuthenticationMethods-select"
                        multiple
                        fullWidth
                        value={body.clientAuthenticationMethods}
                        // @ts-ignore
                        onChange={event => updateBody({clientAuthenticationMethods: event.target.value.map(value => ClientAuthenticationMethod[value])})}
                        input={<OutlinedInput id="clientAuthenticationMethods-select-multiple-chip"
                                              label="Authentication methods"/>}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {
                                    selected.map((value) => {
                                            let label = 'Unknown';
                                            switch (value) {
                                                case ClientAuthenticationMethod.CLIENT_SECRET_BASIC:
                                                    label = 'Basic';
                                                    break;
                                                case ClientAuthenticationMethod.CLIENT_SECRET_POST:
                                                    label = 'Post';
                                                    break;
                                                case ClientAuthenticationMethod.CLIENT_SECRET_JWT:
                                                    label = 'JWT';
                                                    break;
                                                case ClientAuthenticationMethod.PRIVATE_KEY_JWT:
                                                    label = 'Private Key';
                                                    break;
                                                case ClientAuthenticationMethod.NONE:
                                                    label = 'None';
                                                    break;
                                            }

                                            return (<Chip key={value} label={label}/>)
                                        }
                                    )}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={ClientAuthenticationMethod.NONE}>None</MenuItem>
                        <MenuItem value={ClientAuthenticationMethod.CLIENT_SECRET_BASIC}>Basic</MenuItem>
                        <MenuItem value={ClientAuthenticationMethod.CLIENT_SECRET_POST}>Post</MenuItem>
                        <MenuItem value={ClientAuthenticationMethod.CLIENT_SECRET_JWT}>JWT</MenuItem>
                        <MenuItem value={ClientAuthenticationMethod.PRIVATE_KEY_JWT}>Private Key</MenuItem>

                    </Select>

                    <InputLabel id="authorizationGrantTypes-label">Grant types</InputLabel>
                    <Select
                        labelId="authorizationGrantTypes-label"
                        id="authorizationGrantTypes-select"
                        multiple
                        fullWidth
                        value={body.authorizationGrantTypes}
                        // @ts-ignore
                        onChange={event => updateBody({authorizationGrantTypes: event.target.value.map(value => AuthorizationGrantType[value])})}
                        input={<OutlinedInput id="authorizationGrantTypes-select-multiple-chip"
                                              label="Authentication methods"/>}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {
                                    selected.map((value) => {
                                            let label = 'Unknown';
                                            switch (value) {
                                                case AuthorizationGrantType.AUTHORIZATION_CODE:
                                                    label = 'Authorization code';
                                                    break;
                                                case AuthorizationGrantType.REFRESH_TOKEN:
                                                    label = 'Refresh token';
                                                    break;
                                                case AuthorizationGrantType.CLIENT_CREDENTIALS:
                                                    label = 'Client Credentials';
                                                    break;
                                                case AuthorizationGrantType.PASSWORD:
                                                    label = 'Password';
                                                    break;
                                                case AuthorizationGrantType.JWT_BEARER:
                                                    label = 'Bearer';
                                                    break;
                                                case AuthorizationGrantType.DEVICE_CODE:
                                                    label = 'Device code';
                                                    break;
                                            }

                                            return (<Chip key={value} label={label}/>)
                                        }
                                    )}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={AuthorizationGrantType.AUTHORIZATION_CODE}>Authorization code</MenuItem>
                        <MenuItem value={AuthorizationGrantType.REFRESH_TOKEN}>Refresh token</MenuItem>
                        <MenuItem value={AuthorizationGrantType.CLIENT_CREDENTIALS}>Client Credentials</MenuItem>
                        <MenuItem value={AuthorizationGrantType.PASSWORD}>Password</MenuItem>
                        <MenuItem value={AuthorizationGrantType.JWT_BEARER}>Bearer</MenuItem>
                        <MenuItem value={AuthorizationGrantType.DEVICE_CODE}>Device code</MenuItem>

                    </Select>

                    <MuiChipsInput value={Array.from(body.scopes)}
                                   label={"Scopes"}
                                   sx={{mt: 2}}
                                   fullWidth
                                   onChange={value => updateBody({scopes: value})}/>

                    <MuiChipsInput value={Array.from(body.redirectUris)}
                                   label={"Redirect URIs"}
                                   sx={{mt: 2}}
                                   fullWidth
                                   onChange={value => updateBody({redirectUris: value})}/>

                    <MuiChipsInput value={Array.from(body.postLogoutRedirectUris)}
                                   label={"Post logout URIs"}
                                   sx={{mt: 2}}
                                   fullWidth
                                   onChange={value => updateBody({postLogoutRedirectUris: value})}/>

                    <Typography sx={{mt: 2}} component="h1" variant="h5" align={"center"}>
                        Client settings
                    </Typography>

                    <TextField
                        id="jwkSetUrl"
                        label="JWK Set URL"
                        margin="normal"
                        value={body.clientSettings.jwkSetUrl || ''}
                        onChange={event => updateClientSettings({jwkSetUrl: event.target.value})}
                        required
                        fullWidth
                    />

                    <TextField
                        id="tokenEndpointAuthenticationSigningAlgorithm"
                        label="Signing algorithm"
                        margin="normal"
                        value={body.clientSettings.tokenEndpointAuthenticationSigningAlgorithm || ''}
                        onChange={event => updateClientSettings({tokenEndpointAuthenticationSigningAlgorithm: event.target.value})}
                        required
                        fullWidth
                    />

                    <FormControlLabel
                        control={<Checkbox
                            id="requireAuthorizationConsent"
                            checked={body.clientSettings.requireAuthorizationConsent}
                            onChange={event => updateClientSettings({requireAuthorizationConsent: event.target.checked})}
                            color="primary"/>}
                        label="Require auth consent"
                    />


                    <FormControlLabel
                        control={<Checkbox
                            id="requireProofKey"
                            checked={body.clientSettings.requireProofKey}
                            onChange={event => updateClientSettings({requireProofKey: event.target.checked})}
                            color="primary"/>}
                        label="Require proof key"
                    />

                    <Typography sx={{mt: 2}} component="h1" variant="h5" align={"center"}>
                        Token settings
                    </Typography>


                    <TextField
                        id="authorizationCodeTimeToLive"
                        label="Auth code TTL"
                        type={"number"}
                        margin="normal"
                        value={body.tokenSettings.authorizationCodeTimeToLive}
                        onChange={event => updateTokenSettings({authorizationCodeTimeToLive: Number(event.target.value)})}
                        required
                        fullWidth
                    />

                    <TextField
                        id="accessTokenTimeToLive"
                        label="Access token TTL"
                        type={"number"}
                        margin="normal"
                        value={body.tokenSettings.accessTokenTimeToLive}
                        onChange={event => updateTokenSettings({accessTokenTimeToLive: Number(event.target.value)})}
                        required
                        fullWidth
                    />

                    {/*<TextField*/}
                    {/*    id="accessTokenFormat"*/}
                    {/*    label="Token format"*/}
                    {/*    margin="normal"*/}
                    {/*    value={body.tokenSettings.accessTokenFormat || ''}*/}
                    {/*    onChange={event => updateTokenSettings({accessTokenFormat: Number(event.target.value)})}*/}
                    {/*    required*/}
                    {/*    fullWidth*/}
                    {/*/>*/}

                    <InputLabel id="accessTokenFormat-label">Access token format</InputLabel>
                    <Select
                        labelId="accessTokenFormat-label"
                        id="accessTokenFormat-select"
                        value={body.tokenSettings.accessTokenFormat || ''}
                        onChange={event => updateTokenSettings({accessTokenFormat: event.target.value})}
                        fullWidth
                        required
                        label="Access token format"
                    >
                        <MenuItem value={"self-contained"}>Self contained</MenuItem>
                        <MenuItem value={"reference"}>Reference</MenuItem>
                    </Select>

                    <TextField
                        id="deviceCodeTimeToLive"
                        label="Device code TTL"
                        type={"number"}
                        margin="normal"
                        value={body.tokenSettings.deviceCodeTimeToLive}
                        onChange={event => updateTokenSettings({deviceCodeTimeToLive: Number(event.target.value)})}
                        required
                        fullWidth
                    />

                    <FormControlLabel
                        control={<Checkbox
                            id="reuseRefreshTokens"
                            checked={body.tokenSettings.reuseRefreshTokens}
                            onChange={event => updateTokenSettings({reuseRefreshTokens: event.target.checked})}
                            color="primary"/>}
                        label="Reuse refresh tokens"
                    />

                    <InputLabel id="idTokenSignatureAlgorithm-label">ID Token signature algorithm</InputLabel>
                    <Select
                        labelId="idTokenSignatureAlgorithm-label"
                        id="idTokenSignatureAlgorithm-select"
                        value={body.tokenSettings.idTokenSignatureAlgorithm || ''}
                        onChange={event => updateTokenSettings({idTokenSignatureAlgorithm: event.target.value})}
                        fullWidth
                        required
                        label="ID Token signature algorithm"
                    >
                        <MenuItem value={"RS256"}>RS256</MenuItem>
                        <MenuItem value={"RS384"}>RS384</MenuItem>
                        <MenuItem value={"RS512"}>RS512</MenuItem>
                        <MenuItem value={"ES256"}>ES256</MenuItem>
                        <MenuItem value={"ES384"}>ES384</MenuItem>
                        <MenuItem value={"ES512"}>ES512</MenuItem>
                        <MenuItem value={"PS256"}>PS256</MenuItem>
                        <MenuItem value={"PS384"}>PS384</MenuItem>
                        <MenuItem value={"PS512"}>PS512</MenuItem>
                    </Select>

                    <TextField
                        id="refreshTokenTimeToLive"
                        label="Refresh token TTL"
                        type={"number"}
                        margin="normal"
                        value={body.tokenSettings.refreshTokenTimeToLive || ''}
                        onChange={event => updateTokenSettings({refreshTokenTimeToLive: Number(event.target.value)})}
                        required
                        fullWidth
                    />


                    {/*<TextField*/}
                    {/*    id="clientIdIssuedAt"*/}
                    {/*    label="Client issued at"*/}
                    {/*    margin="normal"*/}
                    {/*    value={body.clientIdIssuedAt}*/}
                    {/*    onChange={event => setBody({...body, clientIdIssuedAt: event.target.value})}*/}
                    {/*    required*/}
                    {/*    fullWidth*/}
                    {/*/>*/}

                    {/*<TextField*/}
                    {/*    id="clientIdIssuedAt"*/}
                    {/*    label="Client issued at"*/}
                    {/*    margin="normal"*/}
                    {/*    value={body.clientIdIssuedAt}*/}
                    {/*    onChange={event => setBody({...body, clientIdIssuedAt: event.target.value})}*/}
                    {/*    required*/}
                    {/*    fullWidth*/}
                    {/*/>*/}

                    {/*<TextField*/}
                    {/*    id="clientIdIssuedAt"*/}
                    {/*    label="Client issued at"*/}
                    {/*    margin="normal"*/}
                    {/*    value={body.clientIdIssuedAt}*/}
                    {/*    onChange={event => setBody({...body, clientIdIssuedAt: event.target.value})}*/}
                    {/*    required*/}
                    {/*    fullWidth*/}
                    {/*/>*/}

                    {/*<TextField*/}
                    {/*    id="clientIdIssuedAt"*/}
                    {/*    label="Client issued at"*/}
                    {/*    margin="normal"*/}
                    {/*    value={body.clientIdIssuedAt}*/}
                    {/*    onChange={event => setBody({...body, clientIdIssuedAt: event.target.value})}*/}
                    {/*    required*/}
                    {/*    fullWidth*/}
                    {/*/>*/}

                    {/*<Box width="100%">*/}

                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox*/}
                    {/*        id="enabled"*/}
                    {/*        checked={enabled}*/}
                    {/*        onChange={event => setEnabled(event.target.checked)}*/}
                    {/*        color="primary"/>}*/}
                    {/*    label="Enabled"*/}
                    {/*/>*/}
                    {/*</Box>*/}


                    {/*<MuiChipsInput value={authorities} onChange={value => setAuthorities(value)}/>*/}

                    <Button
                        fullWidth
                        variant="contained"
                        color={"secondary"}
                        onClick={() => saveClient()}
                        sx={{mt: 3, mb: 2}}
                    >
                        Save
                    </Button>

                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => navigate(-1)}
                        sx={{mt: 3, mb: 2}}
                    >
                        Cancel
                    </Button>
                </Box>
            </Container>
        </Box>

    );
}