import * as React from 'react';
import {useEffect, useState} from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../components/Copyright';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import LogoutIcon from "@mui/icons-material/Logout";
import {blue, red} from "@mui/material/colors";
import {Outlet, useNavigate} from "react-router-dom";
import {User} from "../api/classes";
import {getUserProfile} from "../api/users";
import IconButton from "@mui/material/IconButton";
import {Badge} from "@mui/material";

const AppBar = styled(MuiAppBar, {})(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));


const defaultTheme = createTheme({
    palette: {
        primary: red,
        secondary: blue,
    }
});

export default function Dashboard() {
    const navigate = useNavigate();

    const [profile, setProfile] = useState<User | null>(null);

    useEffect(() => {
        getUserProfile()
            .then(value => {
                if (value === undefined) {
                    navigate("/not-allowed");
                }
                if (!value.authorities.includes("ADMIN")) {
                    navigate("/not-allowed");
                }
                setProfile(value);
            });
    }, [navigate])

    const usersChosen = () => {
        navigate("users");
    }
    const clientsChosen = () => {
        navigate("clients");
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute">
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{flexGrow: 1}}
                        >
                            Adoni Bakery SSO: {profile?.username}

                        </Typography>
                        <ListItemButton onClick={() => usersChosen()}>
                            <ListItemIcon>
                                <PeopleIcon sx={{color: 'white'}}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography style={{color: 'white'}}>Users</Typography>}/>
                        </ListItemButton>
                        <ListItemButton onClick={() => clientsChosen()}>
                            <ListItemIcon>
                                <LayersIcon sx={{color: 'white'}}/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{color: 'white'}}>Clients</Typography>}/>
                        </ListItemButton>
                        <IconButton color="inherit" onClick={() => navigate("/logout")}>
                            <Badge color="secondary">
                                <LogoutIcon/>
                            </Badge>
                        </IconButton>

                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                        <Outlet/>
                        <Copyright sx={{pt: 4}}/>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}