import React from 'react';
import {InfinitySpin} from 'react-loader-spinner'
import {logout} from "../api/init";

export default function Logout() {

    logout();

    return (
        <InfinitySpin
            color="red"
        />
    );
};
