class PageResult<T> {
    page: number = 1;
    pageSize: number = 50;
    pageResultSize: number = 0;
    pages: number = 0;
    totalElements: number = 0;
    hasMoreContent: boolean = false;
    content: T[] = [];
}

class User {
    id: string | null = null;
    username: string | null = null;
    password: string | null = null;
    enabled: boolean = true;
    accountExpired: boolean = false;
    accountLocked: boolean = false;
    credentialsExpired: boolean = false;
    authorities: string[] = [];
}

class Client {
    id: string | null = null;
    clientId: string | null = null;
    clientIdIssuedAt: string | null = null; // You can use the appropriate TypeScript type for Timestamp
    clientSecret: string | null = null;
    clientSecretExpiresAt: string | null = null; // You can use the appropriate TypeScript type for Timestamp
    clientName: string | null = null;
    clientAuthenticationMethods: ClientAuthenticationMethod[] = [];
    authorizationGrantTypes: AuthorizationGrantType[] = [];
    redirectUris: string[] = [];
    postLogoutRedirectUris: string[] = [];
    scopes: string[] = [];
    clientSettings: ClientSettings = new ClientSettings();
    tokenSettings: TokenSettings = new TokenSettings();
}

class TokenSettings {
    authorizationCodeTimeToLive: number = 1800;
    accessTokenTimeToLive: number = 1800;
    accessTokenFormat: string = "self-contained";
    deviceCodeTimeToLive: number = 300;
    reuseRefreshTokens: boolean = true;
    refreshTokenTimeToLive: number = 86400;
    idTokenSignatureAlgorithm: string = "RS256";
}

class ClientSettings {
    requireProofKey: boolean = true;
    requireAuthorizationConsent: boolean = false;
    jwkSetUrl: string | null = null;
    tokenEndpointAuthenticationSigningAlgorithm: string | null = null;
}

class EmailBody {
    email: string | null = null;
}

class PasswordBody {
    password: string | null = null;
}

enum AuthorizationGrantType {
    AUTHORIZATION_CODE = 'AUTHORIZATION_CODE',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    CLIENT_CREDENTIALS = 'CLIENT_CREDENTIALS',
    PASSWORD = 'PASSWORD',
    JWT_BEARER = 'JWT_BEARER',
    DEVICE_CODE = 'DEVICE_CODE',
}

enum ClientAuthenticationMethod {
    CLIENT_SECRET_BASIC = 'CLIENT_SECRET_BASIC',
    CLIENT_SECRET_POST = 'CLIENT_SECRET_POST',
    CLIENT_SECRET_JWT = 'CLIENT_SECRET_JWT',
    PRIVATE_KEY_JWT = 'PRIVATE_KEY_JWT',
    NONE = 'NONE',
}


export {
    PageResult,
    User,
    Client,
    ClientSettings,
    TokenSettings,
    ClientAuthenticationMethod,
    AuthorizationGrantType,
    EmailBody,
    PasswordBody
}