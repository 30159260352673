import React from 'react';
import Copyright from "../components/Copyright";

interface NotFoundPageProps {
    copyright?: boolean
}

const NotFoundPage = (props: NotFoundPageProps) => {
    const {copyright = true} = props;
    return (
        <>
            <div style={{textAlign: 'center'}}>
                <h1>404 Not Found</h1>
                <img
                    src="https://media.giphy.com/media/26tn33aiTi1jkl6H6/giphy.gif"
                    alt="Lost Astronaut"
                    style={{width: '300px', height: '300px'}}
                />
                <p>Oops! It seems like you're lost in space!</p>
                <p>Don't worry, our team of astronauts is working hard to find this page.</p>
            </div>
            {
                copyright && (
                    <Copyright sx={{mt: 8, mb: 4}}/>
                )
            }
        </>
    );
};

export default NotFoundPage;
