import {toast} from "react-toastify";

const handleError = (error: Error) => {
    toast.error("Error occurred, contact administrator.")
    console.error('An error occurred:', error);
};

const getUrl = (url: string, page: number, pageSize: number) => {
    return `${url}?page=${page}&pageSize=${pageSize}`
};


export {handleError, getUrl}