import React, {useEffect} from 'react';
import {InfinitySpin} from 'react-loader-spinner'
import {useLocation} from "react-router-dom";

interface RedirectProps {
    prefix: string
    https: boolean
}

export default function Redirect(props: RedirectProps) {
    const {prefix, https} = props;
    const location = useLocation();


    useEffect(() => {
        const url = location.pathname;
        const redirectPath = url.substring(url.indexOf(prefix) + prefix.length);
        const newUrl = (https ? "https://" : "http://") + redirectPath + location.search;

        window.location.replace(newUrl);
    }, [https, location, prefix]);


    return (
        <InfinitySpin
            color="red"
        />
    );
};
