import * as React from 'react';
import {useEffect} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useNavigate, useParams} from 'react-router-dom';
import {getUser, updateUser} from "../../../api/users";
import {User} from "../../../api/classes";
import {toast} from "react-toastify";
import {MuiChipsInput} from 'mui-chips-input'


export default function EditUser() {
    const {id = "unknown"} = useParams();
    const navigate = useNavigate();

    const [userId, setUserId] = React.useState<string | null>('');
    const [username, setUsername] = React.useState<string | null>('');
    const [enabled, setEnabled] = React.useState(false);
    const [accountExpired, setAccountExpired] = React.useState(false);
    const [accountLocked, setAccountLocked] = React.useState(false);
    const [credentialsExpired, setCredentialsExpired] = React.useState(false);
    const [body, setBody] = React.useState<User | null>(null);
    const [authorities, setAuthorities] = React.useState<string[]>([]);

    const saveUser = () => {
        if (!body) {
            toast.error("Cannot save, body is null.");
            return
        }
        body.username = username;
        body.id = id;
        body.enabled = enabled;
        body.authorities = authorities;

        updateUser(id, body)
            .then(value => {
                if (value === undefined) {
                    return;
                }
                toast.success("User updated.");
                fillInUser();
            })

    }

    const fillInUser = () => {
        getUser(id)
            .then(user => {
                if (user === undefined) {
                    return;
                }

                setUserId(user.id);
                setUsername(user.username);
                setEnabled(user.enabled);
                setAccountExpired(user.accountExpired);
                setAccountLocked(user.accountLocked);
                setCredentialsExpired(user.credentialsExpired);
                setAuthorities(user.authorities);
                setBody(user);
            });
    }

    useEffect(() => {
        fillInUser();
        // eslint-disable-next-line
    }, [id]);


    return (
        <Box
            component="main"
            sx={{
                backgroundColor: "white",
                flexGrow: 1,
                overflow: 'auto',
            }}
        >
            <Container maxWidth="sm" sx={{mt: 4, mb: 4}}>
                <Typography component="h1" variant="h5" align={"center"}>
                    Edit user
                </Typography>
                <Box component="form" sx={{mt: 1}}>
                    <TextField
                        id="userId"
                        label="User ID"
                        margin="normal"
                        value={userId}
                        onChange={event => setUserId(event.target.value)}
                        required
                        fullWidth
                    />
                    <TextField
                        id="username"
                        label="Username"
                        margin="normal"
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                        required
                        fullWidth
                    />

                    <Box width="100%">

                        <FormControlLabel
                            control={<Checkbox
                                id="enabled"
                                checked={enabled}
                                onChange={event => setEnabled(event.target.checked)}
                                color="primary"/>}
                            label="Enabled"
                        />
                    </Box>

                    <FormControlLabel
                        control={<Checkbox
                            id="account-expired"
                            checked={accountExpired}
                            color="primary"
                            disabled/>}
                        label="Account expired"
                    />

                    <FormControlLabel
                        control={<Checkbox
                            id="account-locked"
                            checked={accountLocked}
                            color="primary"
                            disabled/>}
                        label="Account locked"
                    />

                    <FormControlLabel
                        control={<Checkbox
                            id="credentials-expired"
                            checked={credentialsExpired}
                            color="primary"
                            disabled/>}
                        label="Credentials expired"
                    />

                    <MuiChipsInput value={authorities} onChange={value => setAuthorities(value)}/>

                    <Button
                        fullWidth
                        variant="contained"
                        color={"secondary"}
                        onClick={() => saveUser()}
                        sx={{mt: 3, mb: 2}}
                    >
                        Save
                    </Button>

                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => navigate(-1)}
                        sx={{mt: 3, mb: 2}}
                    >
                        Cancel
                    </Button>
                </Box>
            </Container>
        </Box>

    );
}