import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Copyright from "../components/Copyright";
import {requestResetPassword} from "../api/password";

import PasswordIcon from '@mui/icons-material/Password';
import Link from "@mui/material/Link";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const defaultTheme = createTheme();
const EMAIL_REGEX = new RegExp("^[\\w-.]+@([\\w-]+\\.)+[\\w-]{1,5}$")

export default function ResetPassword() {
    const [email, setEmail] = React.useState<string>('');
    const [success, setSuccess] = React.useState<boolean>(false);

    const handleSubmit = () => {
        requestResetPassword(email).then(ok => {
            if (ok) {
                setSuccess(true);
            }
        });
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                {
                    !success ?
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                                <PasswordIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Reset Password
                            </Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                error={!EMAIL_REGEX.test(email) && email.length > 0}
                                helperText={!EMAIL_REGEX.test(email) && email.length > 0 && "i.e. hello@world.com"}
                                onChange={event => setEmail(event.target.value)}
                            />
                            <Button
                                fullWidth
                                onClick={_ => handleSubmit()}
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                disabled={!EMAIL_REGEX.test(email)}
                            >
                                Reset password
                            </Button>
                            <Typography>
                                <Link href="/login">Try to log in instead</Link>
                            </Typography>
                        </Box>
                        :
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                                <CheckCircleIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Success!
                            </Typography>
                            <Typography variant={"h6"} marginTop={5}>
                                If there was an account associated with the email we've sent an email to continue the
                                reset process.
                            </Typography>
                        </Box>

                }
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}