import SimpleTable from "../../components/table/SimpleTable";
import {disableUser, enableUser, getUsers} from "../../api/users";
import {PageResult, User} from "../../api/classes";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import * as React from "react";
import BasicMenu from "../../components/table/BasicMenu";
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";

export default function DashboardUsers() {

    const navigate = useNavigate();

    const getUserPage = async (page: number, pageSize: number): Promise<PageResult<User>> => {
        return await getUsers(page, pageSize);
    }

    const getTableHead = (): React.JSX.Element => {
        return (
            <TableHead>
                <TableRow key={"users-table-head"}>
                    <TableCell>User ID</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>
                        <IconButton
                            id="add-user-button"
                            onClick={() => navigate("new")}
                        >
                            <AddIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableHead>
        )
    }

    const getRow = (user: User, refresh: () => void): React.JSX.Element => {
        let id = user.id || "unknown";
        return (
            <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.enabled ? "Enabled" : "Disabled"}</TableCell>
                <TableCell><BasicMenu id={id} enabled={user.enabled} disable={() => {
                    let operation : Promise<User>;
                    if(user.enabled) {
                        operation = disableUser(id);
                    } else {
                        operation = enableUser(id)
                    }
                    operation.then(value => {
                        if(value === undefined) {
                            return;
                        }
                        refresh();
                    });
                }}/></TableCell>

            </TableRow>
        )
    }


    return (
        <>
            <SimpleTable defaultPage={0} defaultRowsPerPage={25} tableHead={getTableHead} getPage={getUserPage}
                         getRow={getRow}/>
        </>
    )
}