import {toast} from "react-toastify";
import {USERS} from "./constants";
import {PageResult, User} from "./classes";
import {getUrl, handleError} from "./common";

const getUserProfile = (): Promise<User> => {
    return fetch(`${USERS}/profile`, {
        method: 'GET',
        redirect: "follow"
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }
            if (response.ok) {
                return response.json();
            } else {
                toast.error("Failed to get profile.")
            }
        })
        .catch(handleError);
}

const getUsers = (page: number, pageSize: number): Promise<PageResult<User>> => {
    return fetch(getUrl(USERS, page, pageSize), {
        method: 'GET',
        redirect: "follow"
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }

            if (response.ok) {
                return response.json();
            } else {
                toast.error("Failed to get users.")
            }
        })
        .catch(handleError);
};

const getUser = (id: string): Promise<User> => {
    return fetch(`${USERS}/${id}`, {
        method: 'GET',
        redirect: "follow"
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }

            if (response.ok) {
                return response.json();
            } else {
                toast.error(`Failed to get user ${id}`)
            }
        })
        .catch(handleError);
};

const updateUser = (id: string, body: User): Promise<User> => {
    return fetch(`${USERS}/${id}`, {
        method: 'PUT',
        redirect: "follow",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }

            if (response.ok) {
                return response.json();
            } else {
                toast.error(`Failed to update user ${id}`)
            }
        })
        .catch(handleError);
};

const createUser = (body: User): Promise<User> => {
    return fetch(`${USERS}`, {
        method: 'POST',
        redirect: "follow",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }

            if (response.ok) {
                return response.json();
            } else {
                toast.error(`Failed to create user.`);
            }
        })
        .catch(handleError);
};

const enableUser = (id: string): Promise<User> => {
    return fetch(`${USERS}/${id}`, {
        method: 'POST',
        redirect: "follow",
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }
            if (response.ok) {
                return response.json();
            } else {
                toast.error(`Failed to disable user.`);
            }
        })
        .catch(handleError);
};

const disableUser = (id: string): Promise<User> => {
    return fetch(`${USERS}/${id}`, {
        method: 'DELETE',
        redirect: "follow",
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }
            if (response.ok) {
                return response.json();
            } else {
                toast.error(`Failed to disable user.`);
            }
        })
        .catch(handleError);
};

export {getUserProfile, getUsers, getUser, updateUser, createUser, enableUser, disableUser}