import React from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import Login from "./pages/Login";
import 'react-toastify/dist/ReactToastify.css';
import Logout from "./pages/Logout";
import NotFoundPage from "./pages/NotFoundPage";
import Dashboard from "./pages/Dashboard";
import {ToastContainer} from 'react-toastify';
import DashboardUsers from "./containers/dashboard/DashboardUsers";
import DashboardClients from "./containers/dashboard/DashboardClients";
import EditUser from "./containers/dashboard/users/EditUser";
import CreateUser from "./containers/dashboard/users/CreateUser";
import CreateOrEditClient from "./containers/dashboard/clients/CreateOrEditClient";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Redirect from "./pages/Redirect";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import NotAllowed from "./pages/NotAllowed";

function App() {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
                <Routes>
                    <Route path={"/reset-password"} element={<ResetPassword/>}/>
                    <Route path={"/reset-password/:id"} element={<ResetPasswordConfirm/>}/>
                    <Route path={"/login"} element={<Login/>}/>
                    <Route path={"/logout"} element={<Logout/>}/>
                    <Route path={"/"} element={<Navigate to={"/login"}/>}/>
                    <Route path={"/redirect-http/*"} element={<Redirect prefix={"/redirect-http/"} https={false}/>}/>
                    <Route path={"/redirect-https/*"} element={<Redirect  prefix={"/redirect-https/"} https={true}/>}/>
                    <Route path={"/logged-in"} element={<Navigate to={"/dashboard"}/>}/>
                    <Route path={"/dashboard"} element={<Dashboard/>}>
                        <Route index element={<Navigate to={'users'}/>}/>

                        <Route path="users" element={<DashboardUsers/>}/>
                        <Route path="users/*">
                            <Route path={":id"} element={<EditUser/>}/>
                            <Route path={"new"} element={<CreateUser/>}/>
                            <Route path={"unknown"} element={<NotFoundPage copyright={false}/>}/>
                        </Route>

                        <Route path="clients" element={<DashboardClients/>}/>
                        <Route path="clients/*">
                            <Route path={":id"} element={<CreateOrEditClient/>}/>
                            <Route path={"new"} element={<CreateOrEditClient isCreate/>}/>
                            <Route path={"unknown"} element={<NotFoundPage copyright={false}/>}/>
                        </Route>
                    </Route>
                    <Route path={"/not-allowed"} element={<NotAllowed/>}/>
                    <Route path={"/*"} element={<NotFoundPage/>}/>
                </Routes>
                <ToastContainer position={"bottom-left"} theme={"colored"}/>
            </BrowserRouter>
        </LocalizationProvider>
    );
}

export default App;
