import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import {ListItemIcon, ListItemText} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

interface BasicMenuProps {
    id: string;
    canEdit?: boolean;
    canDisable?: boolean;
    isDelete?: boolean;
    disable: () => void
    enabled?: boolean;
}

export default function BasicMenu(props: BasicMenuProps) {
    const {id, canEdit = true, canDisable = true, enabled = true, disable, isDelete = false} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MenuIcon/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    canEdit && (
                        <MenuItem onClick={() => navigate(id)}>
                            <ListItemIcon>
                                <EditIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                        </MenuItem>)
                }
                {
                    canDisable && (
                        <MenuItem onClick={() => {
                            disable();
                            setAnchorEl(null);
                        }}>
                            <ListItemIcon>
                                {
                                    enabled ? <CancelIcon fontSize="small"/> : <CheckIcon fontSize="small"/>
                                }
                            </ListItemIcon>
                            <ListItemText>{enabled ? isDelete ? 'Delete' : 'Disable' : 'Enable'}</ListItemText>
                        </MenuItem>)
                }
            </Menu>
        </div>
    );
}