import {toast} from "react-toastify";
import {CLIENTS} from "./constants";
import {Client, PageResult} from "./classes";
import {getUrl, handleError} from "./common";


const getClients = (page: number, pageSize: number): Promise<PageResult<Client>> => {
    return fetch(getUrl(CLIENTS, page, pageSize), {
        method: 'GET',
        redirect: "follow",
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }

            if (response.ok) {
                return response.json();
            } else {
                toast.error("Failed to get clients.")
            }
        })
        .catch(handleError);
};

const getClient = (id: string): Promise<Client> => {
    return fetch(`${CLIENTS}/${id}`, {
        method: 'GET',
        redirect: "follow"
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }

            if (response.ok) {
                return response.json();
            } else {
                toast.error(`Failed to get client ${id}`)
            }
        })
        .catch(handleError);
};

const updateClient = (id: string, body: Client): Promise<Client> => {
    return fetch(`${CLIENTS}/${id}`, {
        method: 'PUT',
        redirect: "follow",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }

            if (response.ok) {
                return response.json();
            } else {
                toast.error(`Failed to update client ${id}`)
            }
        })
        .catch(handleError);
};

const createClient = (body: Client): Promise<Client> => {
    return fetch(`${CLIENTS}`, {
        method: 'POST',
        redirect: "follow",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }

            if (response.ok) {
                return response.json();
            } else {
                toast.error(`Failed to create client.`);
            }
        })
        .catch(handleError);
};

const deleteClient = (id: string): Promise<void> => {
    return fetch(`${CLIENTS}/${id}`, {
        method: 'DELETE',
        redirect: "follow",
    })
        .then((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }
            if (response.ok) {
                return;
            } else {
                toast.error(`Failed to disable client.`);
            }
        })
        .catch(handleError);
};

export {getClients, getClient, updateClient, createClient, deleteClient}